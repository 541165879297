@font-face {
  font-family: "NunitoMedium";
  src: local("NunitoMedium"),
    url("../assets/fonts/Nunito-Medium.ttf") format("opentype");
  font-weight: normal;
}

p,
a,
h2,
h3 {
  font-family: "NunitoMedium";
  color: #1E0B65;
}


#intro {
  background-image: url("../public/purple_background.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  height: 100vh; /* Makes the section full-height */
  align-items: center; /* Vertically align the content */
  justify-content: center; /* Optional: Center horizontally */
}

#intro .content {
  position: relative; /* Make content above the background */
  z-index: 2; /* Ensures it appears on top */
  color: white; /* Set text color to white for visibility */
}

#intro .container {
  width: 60%;
}

#benefits .container {
  width: 60%;
}

#features .container {
  width: 60%;
}

#how_it_works .container {
  width: 60%;
}

#footer .container {
  width: 60%;
}

.features-subtitle {
  width: 40%;
}

#footer {
  background-image: url("../public/footer_background.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  align-items: center; /* Vertically align the content */
  justify-content: center; /* Optional: Center horizontally */
  padding-top: 5px;
  padding-bottom: 20px;
}

@media (max-width: 768px) {
  #intro .container {
    width: 90%;
  }
  #benefits .container {
    width: 90%;
  }
  #features .container {
    width: 90%;
  }
  #how_it_works .container {
    width: 90%;
  }
  #footer .container {
    width: 90%;
  }
  .features-subtitle {
    width: 90%;
  }
}